import * as React from "react";
import Layout from "../components/layout";

const DataPolicyPage = () => {
    return (
        <main>
            <title>Data Policy</title>
            <Layout>
                <div className="mx-auto items-center flex justify-center max-w-xs">
                    <p className="text-xl">
                        To have us delete your data from all of our systems,
                        please email us from address you created an account with
                        asking us to remove your data.
                        <br />
                        <a href="mailto:support@momolingo.com">
                            support@momolingo.com
                        </a>
                        .
                    </p>
                </div>
            </Layout>
        </main>
    );
};

export default DataPolicyPage;
